import React from "react";
import { Link } from "gatsby";
import moment from "moment";
import BlogHero from "./blog_hero";

export default function Blogs({ data }) {
  return (
    <>
      <BlogHero />
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:mx-16 mt-16">
        {data.allBlogs.blogs.map((post, index) => {
          return (
            <a href={"/blog/" + post.uid} target="_blank">
              <div className=" w-full   flex flex-col justify-start items-center hover:opacity-80">
                <div className="w-80% h-52 lg:h-80 xl:h-100">
                  <img
                    className="h-full  w-full object-cover rounded-3xl"
                    src={post.cover_image.url}
                    alt=""
                  />
                </div>
                <div className="w-80% h-20% px-8   my-8 border-l-2 border-primary-blue flex flex-col justify-center items-center text-center ">
                  <h2 className="text-2xl mt-4  font-heading2 ">
                    {" "}
                    <span className="text-center"> {post.title} </span>{" "}
                  </h2>
                  <p className="font-body1  ">
                    {post.author} |{" "}
                    {moment(post.published_date).format("MMM DD YYYY")}
                  </p>
                </div>
              </div>
            </a>
          );
        })}

        {/* <div className="h-60v bg-red-200 flex flex-col justify-start">
          <div className="h-40v bg-red-500 ">1</div>
        </div>

        <div className="h-60v bg-green-200 flex flex-col justify-end">
          <div className="h-40v bg-green-500 ">2</div>
        </div> */}
      </div>
    </>
  );
}
