import React from "react";
import { graphql } from "gatsby";
import Blogs from "../components/blogs";
import Layout from "../components/layout";

export default function BlogsPage({ location, data }) {
  return (
    <Layout location={location}>
      <Blogs data={data} />
    </Layout>
  );
}

export const query = graphql`
  {
    allBlogs {
      blogs {
        id
        title
        type
        uid
        short_description
        published_date
        author
        author_description
        cover_image {
          url
        }
      }
    }
  }
`;
